import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { APP_COOKIE_NAME, LOGOUT, SHOW_NOTIFICATION } from "../constants";
import { getUserProfile } from "../store/selectors";
import { deleteCookie, getCookie, setCookie } from "../utils";
import socket from "../utils/Socket";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://gtasks-api.deploy.malla.es"
    : "http://localhost:3005"; //EN EL SOCKET ESTA PUESTO TAMBIEN

export const axios = Axios.create({ baseURL: BASE_URL });

const setAccessToken = async (token) => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token || getCookie(APP_COOKIE_NAME)}`,
  };
  setCookie(APP_COOKIE_NAME, token, 1);
};

const chcekAccessToken = async (request) => {
  if (request.headers["Authorization"]) {
    return request;
  } else {
    const token = await getCookie(APP_COOKIE_NAME);
    if (token) {
      setAccessToken(token);
      return { ...request, headers: { Authorization: `Bearer ${token}` } };
    } else {
      return request;
    }
  }
};

var option = false;

const sendUpdatedSession = async ({ config }) => {
  if (config.method !== "get") {
    if (option) {
      option = false;
      socket.emit("updateSession", {
        token: config.headers.Authorization?.split("Bearer ")[1],
        url: config.url?.split("/")[1],
      });
    } else {
      option = true;
    }
  }
};

const ConnectionHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(getUserProfile);

  const showNotification = (message = "HA OCURRIDO UN ERROR") => {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: { show: true, status: "error", message },
    });
  };

  const exitFromApp = () => {
    deleteCookie(APP_COOKIE_NAME);
    dispatch({ type: LOGOUT });
    if (profile?.establishment === "612ddeafa78d6a0019aa4645") {
      history.push("login/CONGO");
    }
  };

  axios.interceptors.request.use(
    async (request) => {
      const config = await chcekAccessToken(request);
      return config;
    },
    (err) => {
      const {
        status = 0,
        data: { message = "" },
        config: { url = "" },
      } = err?.response || {};
      console.log("⛔️ REQUEST ERROR (Target)=> ", url);
      console.log("⛔️ REQUEST ERROR (Message)=> ", message);
      console.log("⛔️ REQUEST ERROR (Code) => ", status);
      console.log("➕➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➕");
      showNotification(`${message}`);
      return err?.response;
      // showNotification(`${message} => TARGET: ${url}`);
    }
  );

  axios.interceptors.response.use(
    (config) => {
      config && sendUpdatedSession(config);
      if (
        config &&
        (config.config.url === `/user/login` ||
          config.config.url === `/user/check2FA`)
      ) {
        setAccessToken(config.data.access_token);
      }
      return config;
    },
    (err) => {
      const {
        status = 0,
        data: { message = "" },
        config: { url = "" },
      } = err?.response || {};
      console.log("⛔️ RESPONSE ERROR (Target) => ", url);
      console.log("⛔️ RESPONSE ERROR (Message) => ", message);
      console.log("⛔️ RESPONSE ERROR (Code) => ", status);
      console.log("➕➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➕");
      switch (status) {
        case 401:
          if (message === "Invalid username or password") {
            showNotification(`Usuario o contraseña incorrectos.`);
            exitFromApp();
            return;
          }
          if (message !== "Invalid token") showNotification(`${message}`);
          exitFromApp();
          return;
        default:
          break;
      }
      showNotification(`${message}`);
      return err?.response;
      // showNotification(`${message} => TARGET: ${url}`);
    }
  );

  return null;
};

export default ConnectionHandler;
